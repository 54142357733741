export const customerSettings = {
COMPANY_CODE: "myway",
MODE: "production",
ENV: "qa",
VERSION: 1.1,
DOMAIN: 'qa.myway.be',
SCRIPTS: [{'src': '//js-eu1.hs-scripts.com/25237029.js', 'async': true, 'defer': true, 'id': 'hs-script-loader'}],
FONTS: [],
SENTRY_DSN: "https://356d953cae7ac936b8693143d56d2cbd@o1290476.ingest.us.sentry.io/4507509201764352",
LANGUAGES: ['nl', 'fr'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,
WEBSITE_NAME: "",
IMAGE_PROMO_POSITION: "",
PATH_PREFIX: "",

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_SEARCH_CONSOLE_SITE_VERIFICATION: 'wm67dxt19WgSsmQjVfJoi0VWjqA-QVh9oxj2r4z1vM4',
GOOGLE_TAG_MANAGER_ID: 'GTM-5HSG9RH',
GDDL_ID: 'GTM-5HSG9RH',
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
INTERCOM_ID: '',
FINANCING: true,
FINANCING_SETTINGA: 'Example',
FINANCING_TEXT: true,
FAVORITES: true,
VEHICLE_COMPARE: true,
DEALER_GROUPS: false,
SEO: true,
CONSENT: false,
CONSENT_SCRIPT_URL: 'https://cmp.myway.be/dieteren/myway_prod/Bootstrap.js',
CONSENT_COOKIE_NAME: 'DIETEREN_ENSIGHTEN_PRIVACY_Analysis_and_Personalization',
VEHICLE_LIST_DEFAULT_SORT: '-publicationDate',
ACCOUNT: true,
BLOG: true,
FAQ: true,
PURCHASE: true
};